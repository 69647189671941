import topone from "../../assets/images/homepage/ourTopProducts/topone.jpg"
import toptwo from "../../assets/images/homepage/ourTopProducts/toptwo.jpg"
import topthree from "../../assets/images/homepage/ourTopProducts/topthree.jpg"
import topfour from "../../assets/images/homepage/ourTopProducts/topfour.jpg"
import topfive from "../../assets/images/homepage/ourTopProducts/topfive.jpg"
import topsix from "../../assets/images/homepage/ourTopProducts/topsix.jpg"
import topseven from "../../assets/images/homepage/ourTopProducts/topseven.jpg"
import topeight from "../../assets/images/homepage/ourTopProducts/topeight.jpg"



export const topProductsData =  [
    {
        id: 1,
        img: topone
    }, {
        id: 2,
        img: toptwo
    }, {
        id: 3,
        img: topthree
    }, {
        id: 4,
        img: topfour
    }, {
        id: 5,
        img: topfive
    }, {
        id: 6,
        img: topsix
    }, {
        id: 7,
        img: topseven
    }, {
        id: 8,
        img: topeight
    },
]