import React, { useState } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { styles } from './style';
import ContactImg from '../../../assets/images/contact-us.png';
import sendMail from '../../mail';

const SectionOne = () => {
  const [formData, setFormData] = useState({
    SingleLine: '',
    SingleLine1: '',
    SingleLine2: '',
    SingleLine3: '',
    SingleLine4: '',
    SingleLine5: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

 const sendEmail =() =>{
  let text = `Name: ${formData.SingleLine} \nEmail: ${formData.SingleLine1} \n`
  + `Phone Number: ${formData.SingleLine2} \nWallpaper: ${formData.SingleLine3} \n`
  + `Address: ${formData.SingleLine4} \nMessage: ${formData.SingleLine5} `;
      
  let subject = "Discussion - Walls need a makeover";
 
  if(formData.SingleLine.length > 0 && formData.SingleLine1.length > 0
    && formData.SingleLine2.length > 0 && formData.SingleLine5.length > 0){
      sendMail(subject,text);
  }

 }

  return (
    <Box position="relative" backgroundColor="#1A191A" pb="67px">
      <img src={ContactImg} style={styles.contactImg} alt="Contact Us" />
      <Container maxWidth="lg">
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={12} md={6}>
            <div data-aos="fade-up">
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'start',
                  justifyContent: 'space-around',
                }}
              >
                <Typography
                  mt={5}
                  sx={{ fontSize: '36px', fontWeight: '500', color: 'white' }}
                >
                 Your Walls need a makeover? <br />Let's discuss details.
                </Typography>
               
              </Box>
            </div>
          </Grid>
          <Grid mt={4} item xs={12} md={6}>
            <div data-aos="fade-down">
              <form
                name="form"
                id="form"
                method="POST"
                acceptCharset="UTF-8"
                encType="multipart/form-data"
              >
                <Grid container spacing={2}>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="SingleLine"
                      label="Your Name"
                      name="SingleLine"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="SingleLine1"
                      label="Your Email"
                      name="SingleLine1"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine1}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="SingleLine2"
                      label="Phone Number"
                      name="SingleLine2"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine2}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item md={6} xs={12}>
                    <TextField
                      id="SingleLine3"
                      label="Wallpaper"
                      name="SingleLine3"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine3}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="SingleLine4"
                      label="Address"
                      name="SingleLine4"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine4}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id="SingleLine5"
                      label="Message"
                      name="SingleLine5"
                      variant="standard"
                      fullWidth
                      sx={{ ...styles.input, input: { color: 'white' } }}
                      value={formData.SingleLine5}
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} mt={2}>
                    <Button
                      disableRipple
                      size="small"
                      sx={styles.submitBtn}
                      onClick={sendEmail}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default SectionOne;
