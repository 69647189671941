import React from "react";
import { Route, Routes } from "react-router-dom";
import { Navbar } from "./components/navbar";
import HomeSection from "./sections/home";
import Footer from "./components/footer";
import AOS from "aos";
import { ContactUs } from "./sections/contact-us";
import Products from "./sections/products";
import "./style.css";
import "aos/dist/aos.css";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { ProductDetail } from "./sections/product-detail";
import { About } from "./sections/about";
import { ProductDetailTwo } from "./sections/productdetail-two";
import { ProductDetailThree } from "./sections/productsdetail-three";
import { ProductDetailFour } from "./sections/productdetails-four";
import { ProductDetailFive } from "./sections/productsdetail-five";
import { ProductDetailSix } from "./sections/productdetail-six";
import { ProductDetailSeven } from "./sections/productdetail-seven";
import { ProductDetailEight } from "./sections/productdetail-eight";
import { ProductDetailTen } from "./sections/productdetail-ten";
import { ProductDetailNine } from "./sections/productdetail-nine";



const App = () => {
  AOS.init();
  return (
    <>
      <body>
        <Navbar />
        <Routes>
          <Route element={<HomeSection />} path="/" />
          <Route element={<ContactUs />} path="/contact-us" />
          <Route element={<Products />} path="/products" />
          <Route element={<ProductDetail />} path="/products-detail" />
          <Route element={<ProductDetailTwo />} path="/productdetail-two" />
          <Route element={<ProductDetailThree />} path="/productsdetail-three" />
          <Route element={<ProductDetailFour />} path="/productdetails-four" />
          <Route element={<ProductDetailFive />} path="/productsdetail-five" />
          <Route element={<ProductDetailSix />} path="/productdetail-six" />
          <Route element={<ProductDetailSeven />} path="/productdetail-seven" />
          <Route element={<ProductDetailEight />} path="/productdetail-eight" />
          <Route element={<ProductDetailNine />} path="/productdetail-nine" />
          <Route element={<ProductDetailTen />} path="/productdetail-ten" />







          <Route element={<About />} path="/about" />
        </Routes>
        <Footer />
      </body>

    </>
  );
};

export default App;
