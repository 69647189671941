import React, { useEffect, useState } from "react";
import {
  Box,
  Drawer,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { styles } from "./style";
import bgImg from "../../assets/images/navbar-bg-img.png";
import Logo from "../../assets/images/logo.png";
import InstaLogo from "../../assets/logo/instagram.png";
import FbLogo from "../../assets/logo/facebook.png";
import { Menu as MenuIcon } from "@mui/icons-material";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const menus = [
  { id: "1", name: "home", path: "/" },
  { id: "2", name: "about", path: "/about" },
  { id: "3", name: "products", path: "/products " },
  { id: "4", name: "contact", path: "/contact-us" },
];

export const Navbar = () => {
  const isSmallScreen = useMediaQuery("(max-width:1120px)");
  const isMobileScreen = useMediaQuery("(max-width:750px)");

  const [openDrawer, setOpenDrawer] = useState(false);

  useEffect(() => {
    if (openDrawer) setOpenDrawer(false);
  }, [isMobileScreen]);

  const handleDrawerClose = () => {
    setOpenDrawer(false);
  };

  return (
    <>
      <Box sx={styles.toolbar}>
        <img src={bgImg} className="_img" alt="bg" />
        <Box>
          <Link to="/"> 
            <img src={Logo} alt="logo" height={60} />
          </Link>
        </Box>
        {!isMobileScreen && (
          <Box sx={styles.menus}>
            {menus?.map((item) => (
              <Typography key={item.id} sx={styles.menuItem}>
                <Link to={item.path}>{item.name}</Link>
              </Typography>
            ))}
          </Box>
        )}
        {!isSmallScreen && (
          <Box sx={styles.right_side}>
            <Box sx={styles.icons_wrapper}>
              <Typography style={{color: 'white'}}>Contact us - +923341666222</Typography>
              <a href="https://www.instagram.com/livingstylepk?igsh=Mm5mcXhtYmw3MWVq&utm_source=qr" target="_blank" rel="noopener noreferrer">
                <img src={InstaLogo} alt="Instagram" />
              </a>
              <a href="https://www.facebook.com/LivingStylePAK?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <img src={FbLogo} alt="Facebook" />
              </a>
            </Box>
          </Box>
        )}
        {isMobileScreen && (
          <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
            <MenuIcon sx={{ color: "#fff" }} />
          </IconButton>
        )}
      </Box>
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={handleDrawerClose}
        PaperProps={{
          sx: { width: "220px", background: "#222", padding: "40px 20px" },
        }}
      >
        <Link to="/" onClick={handleDrawerClose}>
          <img src={Logo} alt="logo" height={60} />
        </Link>
        <Box sx={{ ...styles.menus, flexDirection: "column", mt: "30px" }}>
          {menus?.map((item) => (
            <Typography key={item.id} sx={styles.menuItem}>
              <Link to={item.path} onClick={handleDrawerClose}>{item.name}</Link>
            </Typography>
          ))}
        </Box>

        <Box sx={styles.icons_wrapper} mt="30px">
          <a href="https://www.instagram.com/livingstylepk?igsh=Mm5mcXhtYmw3MWVq&utm_source=qr" target="_blank" rel="noopener noreferrer">
            <img src={InstaLogo} alt="Instagram" />
          </a>
          <a href="https://www.facebook.com/LivingStylePAK?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
            <img src={FbLogo} alt="Facebook" />
          </a>
        </Box>
      </Drawer>
    </>
  );
};
