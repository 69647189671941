import React from "react";
import HeroSection from "../../components/hero-section";
import { OurTopProducts } from "../../components/our-top-products";
import NewArrivals from "../../components/new-arrivals";
import Rooms from "../../components/rooms";

const HomeSection = () => {
  return (
    <div>
      <HeroSection />
      <NewArrivals />
      <Rooms />
      <OurTopProducts />
    </div>
  );
};

export default HomeSection;
