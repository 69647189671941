import React, { useState } from "react";
import MessageIcon from "../../../assets/logo/message.png";
import { Box, Button, Container, TextField, Typography } from "@mui/material";
import sendMail from "../../mail";


const sendEmail = () =>{
  let text = `Email: ${ document.getElementById("sectionTwoEmail").value}`;
  let subject = "Subscribe";
  if(document.getElementById("sectionTwoEmail").value != undefined){
    if(document.getElementById("sectionTwoEmail").value != null && document.getElementById("sectionTwoEmail").value.length > 0){
      sendMail(subject,text);
    }
  }
}

const SectionTwo = () => {
  return (
    <div data-aos="flip-up">
      <Box
        style={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          position: "relative",
          padding: "101px 0px 10px",
        }}
      >
        <Container maxWidth="sm">
          <Typography
            variant="h5"
            fontWeight={800}
            color="#1F1F1FE5"
            mb="50px"
            textAlign="center"
          >
            Subscribe to our newsletter for new  <br /> wallpaper collections
          </Typography>
          <Typography variant="body1" color="#1F1F1FE5" textAlign="center">
            Be the first to discover trends, inspiration and special offers we are launching. Join the list now.
          </Typography>

          <Box
            display="flex"
            alignItems="center"
            gap="19px"
            backgroundColor="#F6F5F3"
            p="30px 24px"
            borderRadius="4px"
            sx={{ transform: "translateY(60px)" }}
          >
            <img src={MessageIcon} alt="" height={20} />
            <TextField
              id="sectionTwoEmail"
              fullWidth
              variant="standard"
              placeholder="Enter Your Email Address"
              sx={{
                "& .MuiFormLabel-root": {
                  color: "#222",
                },
                "& .MuiInputBase-root": {
                  "&::before": {
                    borderColor: "#222",
                  },
                  "&::after": {
                    borderColor: "#222",
                  },
                },
              }}
            />
            <Button
              disableRipple
              sx={{
                color: "white",
                width: "275px",
                height: "50px",
                backgroundColor: "black",
                borderRadius: "4px",
                "&:hover": {
                  color: "white",
                  backgroundColor: "black",
                },
              }}
              onClick={sendEmail}
            >
              Subscribe
            </Button>
          </Box>
        </Container>
        {/* <Box
          style={{
            height: "100%",
            width: "50%",
            position: "absolute",
            top: "60px",
            zIndex: 2,
            backgroundColor: "white",
          }}
        >
          <Box>
            <Typography
              style={{
                fontSize: "28px",
                textAlign: "center",
                fontWeight: "800",
              }}
            >
              Subscribe to Our Newsletter For Wallpapper Design Insights
            </Typography>
            <Typography
              style={{
                fontSize: "18px",
                textAlign: "center",
                fontWeight: "400",
                marginTop: "50px",
              }}
            >
              Be the first to discover trends, inspiration and special offer as{" "}
              <br /> we bring the world of design directly to your inbox
            </Typography>
          </Box>
          <Box
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginTop: "65px",
            }}
          >
            <Box>
              <img
                src={MessageIcon}
                alt=""
                height={20}
                style={{ marginTop: "22px" }}
              />
              <TextField
                id="standard-basic"
                label="Enter Your Email Address"
                variant="standard"
                style={{ marginLeft: "10px" }}
              />
            </Box>
            <Button
              style={{
                height: "50px",
                width: "150px",
                color: "white",
                backgroundColor: "black",
                borderRadius: "4px",
              }}
            >
              Subscribe
            </Button>
          </Box>
        </Box> */}
      </Box>
    </div>
  );
};

export default SectionTwo;
