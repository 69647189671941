import React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import DuoImg from "../../assets/images/homepage/duo.png";
import Heroright from "../../assets/images/heroRight.png";
import { CheckBox } from "@mui/icons-material";
import { Link } from "react-router-dom";

const HeroSection = () => {
  return (
    <Box sx={{ px: { md: "77px", xs: "40px" }, background: "#222", minHeight: "100%" }}>
      <Grid container spacing={2} height="100%">
        <Grid item xs={12} lg={8} sx={{
          backgroundImage: `url(${require("../../assets/images/home-bg.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
          height: '100%'
        }}>
          <Grid container spacing={2} alignItems="center" height="100%">
            <Grid item xs={12} md={6} lg={5}>
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
                <Typography sx={{
                  fontWeight: "700",
                  fontSize: { xs: "35px", md: "50px" },
                  color: "white",
                  lineHeight: "55px",
                  display: { xs: "flex", md: "initial" },
                  alignItems: "center",
                  justifyContent: "center",
                  pt: { md: 0, xs: "40px" },
                  flexWrap: "wrap",
                }}>
                  Interior
                  <Box>Wallpaper</Box>
                  <Box>Design</Box>
                </Typography>
                <Typography sx={{
                  fontWeight: "500",
                  fontSize: { xs: "17px", md: "18px" },
                  color: "white",
                  marginTop: "10px",
                  lineHeight: "32px",
                  display: { xs: "flex", md: "initial" },
                  alignItems: "center",
                  justifyContent: "center",
                  pt: { md: 0, xs: "40px" },
                  flexWrap: "wrap",
                }}>
                  <Box mt={2}>Transform your space with stunning <br /> wallpaper designs</Box>
                </Typography>
                <Box mt={6} sx={{ display: 'flex' }}>
                  <CheckBox sx={{ backgroundColor: 'white', marginRight: '20px' }} />
                  <Typography sx={{ color: "white", fontSize: { xs: '12px', md: '13px' } }}>
                    Living Style - An elegant <br /> blend of interior<br /> design
                  </Typography>
                </Box>
              </div>
            </Grid>

            <Grid item xs={12} md={6} lg={7}>
              <div data-aos="fade-down">
                <Typography mb="14px" sx={{
                  fontWeight: "500",
                  fontSize: { xs: "17px", md: "17px" },
                  color: "white",
                  lineHeight: "35px",
                }}>
                  Elevate your space, 
                  one wall at a time!
                </Typography>
                <LazyLoadImage
                  src={DuoImg}
                  alt=""
                  width="100%"
                  height="100%"
                  effect="blur"
                />
              </div>
              <Box my={5} sx={{ display: 'flex', flexDirection: "column", }}>
                <Typography mb={3} sx={{ color: 'white', fontSize: { xs: '17px', md: '17px' } }}>
                Bringing beauty and elegance to every corner
                </Typography>
                <Link to ="/products-detail"> 
                <Button sx={{
                  height: { xs: '40px', md: '50px' },
                  width: { xs: '200px', md: '250px' },
                  border: '1px solid white',
                  borderRadius: '20px',
                  color: 'white',
                }}>
                  View Products
                </Button>
                </Link>
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12} lg={4} sx={{
          "& img": {
            height: { lg: "100%", xs: "0px" },
            objectFit: { lg: "cover", md: "auto", xs: "cover" },
            width: "100%",
          },
        }}>
          <div
            data-aos="fade-left"
            data-aos-offset="300"
            data-aos-easing="ease-in-sine"
          >
            <LazyLoadImage
              src={Heroright}
              alt="img"
              effect="blur"
            />
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HeroSection;
