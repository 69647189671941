import React from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import { ProductCard } from "../../components/product-card";
import aboutmain from "../../assets/images/aboutmain.jpg";
import { aboutData } from "./data";

export const About = () => {
  return (
    <Box py={{ xs: "50px", md: "100px" }}>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item md={6} xs={12}>
            <div data-aos="zoom-in">
              <Typography fontSize={{ xs: 30, md: 40 }} color="#0D1317" mb={{ xs: "20px", md: "30px" }} style={{ lineHeight: '1.3' }}>
                Design Your Dreams with Our Wallpaper Themes
              </Typography>
              <Typography fontSize={{ xs: 12, md: 15 }} color="#5B5B5B" mb={{ xs: "30px", md: "50px" }}>
                From elegant and sophisticated patterns to bold and vibrant designs, our wallpapers are crafted with the highest quality materials to ensure durability and timeless beauty. Whether you're looking to add a touch of luxury to your living room, create a cozy atmosphere in your bedroom, or make a statement in your office, we have the perfect wallpaper to elevate your space.
                <br />
                <br />
                Our team of experienced designers is here to help you bring your vision to life. We offer personalized consultations to understand your needs and preferences, ensuring that the end result exceeds your expectations. Let our wallpapers be the canvas for your creativity and imagination, transforming your walls into works of art.
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                  <Box flex={1}>
                    <Typography color="#4D4D4D" fontSize={{ xs: 30, md: 40 }}>
                      900+
                    </Typography>
                    <Typography color="#0D1317" fontSize={{ xs: 20, md: 25 }} mb="10px">
                      Wall Designs
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box flex={1}>
                    <Typography color="#4D4D4D" fontSize={{ xs: 30, md: 40 }}>
                      500+
                    </Typography>
                    <Typography color="#0D1317" fontSize={{ xs: 20, md: 25 }} mb="10px">
                      Clients
                    </Typography>
                  
                  </Box>
                </Grid>
              </Grid>
             
            </div>
          </Grid>
          <Grid item md={6} xs={12} mb={2}>
            <div data-aos="fade-up">
              <ProductCard img={aboutmain} imgHeight={{ xs: "auto", md: "100%" }} hideContent />
            </div>
          </Grid>
        </Grid>
       
        <Grid container spacing={2}>
          {aboutData.map((data, i) => (
            <Grid item xs={12} md={4} sm={6} key={i}>
              <div data-aos="zoom-out">
                <ProductCard img={data.img} imgHeight={{ xs: "auto", md: "374px" }} hideContent />
              </div>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
