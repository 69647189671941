import React from "react";
import SectionOne from "./sectionone";
import SectionTwo from "./sectiontwo";
import SectionThree from "./sectionthree";
import { useLocation } from "react-router-dom";

const Footer = () => {
  const { pathname } = useLocation();
  return (
    <>
      {pathname !== "/contact-us" && (
        <>
          <SectionOne />
          <SectionTwo />
        </>
      )}
      <SectionThree />
    </>
  );
};

export default Footer;
