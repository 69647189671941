import aboutone from "../../assets/images/aboutone.jpg"
import abouttwo from "../../assets/images/abouttwo.jpg"
import abouthree from "../../assets/images/aboutthree.jpg"




export const aboutData = [
    {
        id:1,
        img:aboutone
    },
    {
        id:2,
        img:abouttwo
    },
    {
        id:3,
        img:abouthree
    },
]