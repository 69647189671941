export const styles = {
  toolbar: {
    background: "#222",
    boxShadow: "none",
    position: "relative",
    height: "100px",
    padding: { md: "36px 81px !important", xs: "20px" },
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    "& ._img": {
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "196px",
      height: "100%",
    },
  },
  menus: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
  },
  menuItem: {
    fontSize: "20px",
    fontWeight: 500,
    "& a": {
      textDecoration: "none",
      color: "#fff",
      textTransform: "capitalize",
    },
  },
  right_side: {
    display: "flex",
    alignItems: "center",
    // border:'1px solid green',
    gap: "40px",
  },
  inputWrapper: {
    border: "1px solid #fff",
    borderRadius: "50px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    padding: "9px 24px",
    maxWidth: "215px",
    "& .search_input": {
      background: "transparent",
      outline: "none",
      border: "none",
      fontSize: "20px",
      color: "#fff",
    },
  },
  icons_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent:'center',
    gap: "16px",
  },
};
