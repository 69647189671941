export const styles = {
  btn_wrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexWrap: "wrap",
    gap: "32px",
  },
  btn: (isActive) => ({
    border: isActive ? "" : "1px solid #EDECEC",
    borderRadius: "4px",
    color: isActive ? "#fff" : "#181818",
    padding: "18px 22px",
    background: isActive ? "#181818" : "",
    textTransform: "capitalize",
    "&:hover": {
      color: isActive ? "#fff" : "#181818",
      background: isActive ? "#181818" : "",
    },
  }),
  main: {
    backgroundImage: `url(${require("../../assets/images/topproductsreview.png")})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    width: '100%',
    height: "100%",
    display:'flex',
    alignItems:'end',
    justifyContent:'end'
  },
  off:{
    backgroundImage: `url(${require("../../assets/images/off.png")})`,
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    width: '100%',
    height: "300px",
    backgroundPosition: "center",
    borderRadius:'10px'
  }
};
