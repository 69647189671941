export const styles = {
  contactImg: {
    position: "absolute",
    top: 0,
    left: 0,
    width: "60%",
    zIndex: 10,
  },
  submitBtn: {
    background: "#fff",
    color: "#222",
    px: "40px",
    "&:hover": {
      background: "#fff",
      color: "#222",
    },
  },
  input: {
    "& .MuiFormLabel-root": {
      color: "#fff",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        borderColor: "#fff",
      },
      "&::after": {
        borderColor: "#fff",
      },
    },
  },
};
