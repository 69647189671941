import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper/modules";
import { Box, Grid, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ProductCard } from "../product-card"; // Updated to named import
import { newarrivaldata } from "./data";

const NewArrivals = () => {
  const theme = useTheme();
  const isLg = useMediaQuery(theme.breakpoints.up('lg'));
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));

  const slidesPerView = isLg ? 4 : isMd ? 3 : isSm ? 2 : 1;
  const titleFontSize = isLg ? '60px' : isMd ? '50px' : '40px';
  const textFontSize = isLg ? '16px' : '14px';

  return (
    <Box px="30px" py="100px">
      <Grid container spacing={0} >
        <Grid item md={4} xs={12} >
          <div data-aos="fade-right">
            <Box>
            <Typography fontSize={titleFontSize} spaceBetween={2} mb={3}>
              New Arrivals
            </Typography>
            <Typography fontSize={textFontSize} color="#797979">
              Discover the endless possibilities
              with our Wallpaper Collections and redefine the way you experience
              your living spaces and offices. Elevate your home or office with our
              stunning new arrivals and create a space that truly reflects who you are.
            </Typography>
            </Box>
          </div>
        </Grid>
        <Grid item md={8} xs={12}>
          <div data-aos="fade-left">
            <Box sx={styles.wrapper}>
              <Swiper
                slidesPerView={slidesPerView}
                spaceBetween={20}
                pagination={{
                  type: "progressbar",
                }}
                navigation={true}
                modules={[Pagination, Navigation]}
                className="mySwiper"
              >
                {newarrivaldata.map((data, i) => (
                  <SwiperSlide key={i}>
                    <ProductCard
                      hideBtn
                      denseSpacing
                      img={data.img}

                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </Box>
          </div>
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewArrivals;

const styles = {
  wrapper: {
    "& .mySwiper": {
      height: "380px !important",
      "&.swiper-backface-hidden": {
        overflowX: "hidden",
        overflowY: "visible",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      },
      "& .swiper-wrapper": {
        position: "static",
      },
      "& .swiper-button-prev, & .swiper-button-next": {
        top: "103%",
        height: "10px !important",
        width: "10px !important",
        fontSize: "10px !important",
        color: "#222",
        "&::after": {
          fontSize: "10px !important",
        },
      },
      "& .swiper-button-prev": {
        left: "50% !important",
      },
      "& .swiper-button-next": {
        right: "45% !important",
      },
      "& .swiper-pagination": {
        top: "99% !important",
        width: "50% !important",
        borderRadius: "4px",
        background: "rgba(0,0,0,0.09)",
        overflow: "hidden",
        "& .swiper-pagination-progressbar-fill": {
          background: "#222",
          borderRadius: "4px",
        },
      },
    },
  },
};
