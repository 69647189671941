import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../../assets/images/logo.png";
import insta from "../../../assets/logo/instagram.png";
import fb from "../../../assets/logo/facebook.png";
import navone from "../../../assets/images/nav-zero.png";
import navtwo from "../../../assets/images/nav-one.png";
import navthree from "../../../assets/images/nav-two.png";
import navfour from "../../../assets/images/nav-3.png";
import navfive from "../../../assets/images/nav-four.png";
import navsix from "../../../assets/images/nav-five.png";

const SectionThree = () => {
  return (
    <Box sx={{ backgroundColor: "#000000", py: { xs: "50px", md: "91px" }, px: { xs: "10px", md: "20px" } }}>
      <Container maxWidth="lg">
        <Box mb={{ xs: "40px", md: "80px" }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <img src={Logo} alt="logo" width={150} />
                  <Typography mt={{ xs: "20px", md: "15px" }} fontSize={{ xs: 13, md: 16 }} sx={{lineHeight:'30px'}} color="#fff">
                  Welcome to Living Style, where we specialize in creating breathtaking spaces that reflect your unique style and personality.
                  </Typography>
                  <Typography mt={{ xs: "20px", md: "15px" }} fontSize={{ xs: 13, md: 16 }} sx={{lineHeight:'30px'}} color="#fff">Contact us - +923341666222</Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography fontSize={{ xs: 24, md: 30 }} mb="30px" color="#fff">
                    Products
                  </Typography>
                  <Box display="flex" flexDirection="column" gap="20px">
                    {["Chivalry", "Texture", "Italian Chic", "Verona"].map((link) => (
                      <Typography fontSize={{ xs: 16, md: 18 }} color="#fff" key={link} sx={{ cursor: "pointer" }}>
                        {link}
                      </Typography>
                    ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={6} xs={12}>
              <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                  <Typography fontSize={{ xs: 24, md: 30 }} mb="30px" color="#fff">
                    Quick Links
                  </Typography>
                  <Box display="flex" flexDirection="column" gap="20px">
                    <Link to="/products" style={{ textDecoration: 'none' }}>
                      <Typography fontSize={{ xs: 16, md: 18 }} color="#fff">
                        Products
                      </Typography>
                    </Link>
                    <Link to="/about" style={{ textDecoration: 'none' }}>
                      <Typography fontSize={{ xs: 16, md: 18 }} color="#fff">
                        About
                      </Typography>
                    </Link>
                    <Link to="/contact" style={{ textDecoration: 'none' }}>
                      <Typography fontSize={{ xs: 16, md: 18 }} color="#fff">
                        Contact
                      </Typography>
                    </Link>
                    <a href="https://livin-style-delta.vercel.app/products" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
                      <Typography fontSize={{ xs: 16, md: 18 }} color="#fff">
                        Shop Now
                      </Typography>
                    </a>
                  </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                  <Typography color="#fff" fontSize={{ xs: 24, md: 30 }} fontWeight={600} mb="30px">
                    Our Latest Designs
                  </Typography>
                  <Grid container spacing={2}>
                    {[navone, navtwo, navthree, navfour, navfive, navsix].map((imgSrc, index) => (
                      <Grid item md={4} sm={3} xs={6} key={index}>
                        <img src={imgSrc} alt={`img-${index}`} width="100%" />
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            borderTop: "2px solid #4E4C51",
            pt: { xs: "15px", md: "25px" },
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <Typography color="#fff" fontSize={{ xs: 14, md: 18 }}>
            Copyrights © 2024 | All rights reserved
          </Typography>
          <Box display="flex" alignItems="center" flexWrap="wrap" gap={{ xs: "20px", md: "82px" }}>
            <a href="https://hitsofficialuae.com/" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none' }}>
              <Typography color="#fff" fontSize={{ xs: 14, md: 18 }}>
                Developed by HEXAGONAL IT SOLUTIONS
              </Typography>
            </a>
            <Box display="flex" alignItems="center" flexWrap="wrap" gap={{ xs: "10px", md: "20px" }}>
              <a href="https://www.instagram.com/livingstylepk?igsh=Mm5mcXhtYmw3MWVq&utm_source=qr" target="_blank" rel="noopener noreferrer">
                <img src={insta} alt="Instagram" />
              </a>
              <a href="https://www.facebook.com/LivingStylePAK?mibextid=LQQJ4d" target="_blank" rel="noopener noreferrer">
                <img src={fb} alt="Facebook" />
              </a>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default SectionThree;