import roomleft from "../../assets/images/room/roomleft.jpg";
import sliderone from "../../assets/images/room/slider/roomone.jpg";
import slidertwo from "../../assets/images/room/slider/roomtwo.jpg";
import sliderthree from "../../assets/images/room/slider/roomthree.jpg";

export const sliderdata = [
    {
        id: 1,
        img: roomleft
    },
    {
        id: 2,
        img: sliderone
    },
    {
        id: 3,
        img: slidertwo
    },
    {
        id: 4,
        img: sliderthree
    },
]
export const accordiandata = [

    {
        id: 1,
        question: 'Do you deliver all over Pakistan?',
        answer: 'We are currently based in Karachi and delivering all over Karachi. For big orders, we can deliver to other cities as well.',
    },
    {
        id: 2,
        question: 'How to get your services',
        answer: 'You can call on our numbers given below or fill the contact form. Our customer happiness manager will get in touch right away. '
    },
    {
        id: 3,
        question: 'What is your delivery time?',
        answer: 'We can deliver all the orders in 3-4 working days. We offer cash on delivery and fixing of wallpapers too.',
    },

]