import React, { useState } from "react";
import { Box, Button, Container, Grid, Typography } from "@mui/material";
import { ProductCard } from "../product-card";
import { topProductsData } from "./data";
import dp from "../../assets/images/dp.png";
import { styles } from "./style";

export const OurTopProducts = () => {
  const tabArray = [
    "Italian Chic",
    "Special jute",
    "zen",
    "verona",
    "view all",
  ];

  const [active, setActive] = useState("Italian Chic");

  return (
    <Box py="150px">
      <Container maxWidth="lg">
        <Typography
          variant="h3"
          textAlign="center"
          mb="30px"
          fontWeight={400}
          color="#00000"
        >
          Top Products- Our Best Sellers
        </Typography>
       
        <Grid container spacing={2}>
          {topProductsData.map((data, i) => (
            <Grid item lg={3} md={4} sm={6} xs={12} key={i}>
              <div data-aos="zoom-in">
                <Box>
                  <ProductCard
                    img={data.img}
                    // title="Italian Chic"
                    // onClick={() => { }}
                    hideBtn={true}
                  />
                </Box>
              </div>
            </Grid>
          ))}
        </Grid>

        <Grid container my={5} spacing={2}>
          <Box mt={4} sx={{ width: '100%', textAlign: 'center' }}>
            <Typography sx={{ fontSize: { xs: '24px', md: '30px' }, fontWeight: 800 }}>
              Testimonials- Our customers have said their heart out!
            </Typography>
          </Box>

          <Grid item md={6} sm={6} xs={12}>
            <Box sx={styles.main}>
              <Box
                sx={{
                  background: 'black',
                  height: { xs: 'auto', md: '350px' },
                  width: { xs: '100%', md: '350px' },
                  display: 'flex',
                  flexDirection: 'column',
                  padding: { xs: 2, md: 0 },
                }}
              >
                <Typography ml={{ xs: 2, md: 7 }} sx={{ color: 'white', fontSize: { xs: '40px', md: '80px' } }}>
                  “
                </Typography>
                <Typography mx={{ xs: 2, md: 4 }} sx={{ color: 'white', fontSize: { xs: '10px', md: '13px' } }}>
                  Living Style provides you end to end service. Their manager briefed us on the designs, their staff was professional, clean and on time and we got the desired results. 5 stars for the product and the service.
                </Typography>
                <Box mt={{ xs: 2, md: 5 }} ml={{ xs: 2, md: 4 }} sx={{ display: 'flex' }}>
                  <Box>
                    <img src={dp} alt="img" height={50} />
                  </Box>
                  <Box >
                    <Typography ml={3}  sx={{ color: 'white', fontSize: { xs: '15px', md: "18px" }, fontWeight: 400 }}>Ali Raza</Typography>
                    <Typography ml={3} sx={{ color: 'white', fontSize: { xs: '12px', md: "15px" }, fontWeight: 400 }}>Karachi Pakistan</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={6} sm={6} xs={12}>
            <Box sx={styles.main}>
              <Box
                sx={{
                  background: 'black',
                  height: { xs: 'auto', md: '350px' },
                  width: { xs: '100%', md: '350px' },
                  display: 'flex',
                  flexDirection: 'column',
                  padding: { xs: 2, md: 0 },
                }}
              >
                <Typography ml={{ xs: 2, md: 7 }} sx={{ color: 'white', fontSize: { xs: '40px', md: '80px' } }}>
                  “
                </Typography>
                <Typography mx={{ xs: 2, md: 4 }} sx={{ color: 'white', fontSize: { xs: '10px', md: '11px' } }}>
                  We were not sure if we would go for an online company for the revamping of our office, but given a chance to Living Style. It turned out to be magical and we are absolutely blown away by their services. The wallpaper collection is massive, detailing in terms of design is immaculate and we loved the final results. Our office is looking much better. Highly Recommended
                </Typography>
                <Box mt={{ xs: 2, md: 2 }} ml={{ xs: 2, md: 4 }} sx={{ display: 'flex' }}>
                  <Box>
                    <img src={dp} alt="img" height={50} />
                  </Box>
                  <Box>
                    <Typography ml={3} mb={1} sx={{ color: 'white', fontSize: { xs: '15px', md: "18px" }, fontWeight: 400 }}>Syed Zeeshan Ali</Typography>
                    <Typography ml={3} sx={{ color: 'white', fontSize: { xs: '12px', md: "15px" }, fontWeight: 400 }}>Karachi Pakistan</Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>

        </Grid>

        <Grid container>
          <Grid item md={12} sm={12} xs={12}>
            <Box sx={styles.off}>
              <Box sx={{ display: "flex", alignItems: 'start', flexDirection: 'column', textAlign: { xs: 'center', md: 'left' }, padding: { xs: 2, md: 0 } }}>
                <Typography ml={{ xs: 0, md: 12 }} mt={{ xs: 2, md: 6 }} sx={{ color: 'white', fontSize: { xs: '24px', md: "35px" } }}>
                  Special offer and discounts available
                </Typography>
                <Box
                  mt={{ xs: 2, md: 5 }}
                  ml={{ xs: 0, md: 8 }}
                  sx={{
                    gap: '15px',
                    display: 'flex',
                    flexDirection: { xs: 'column', md: 'row' },
                    justifyContent: { xs: 'center', md: 'flex-start' }
                  }}
                >
                  <Button
                    sx={{
                      height: "50px",
                      width: '140px',
                      color: 'white',
                      backgroundColor: 'black',
                      fontWeight: 600,
                      fontSize: '15px',
                      textTransform: 'capitalize'
                    }}
                    onClick={() => window.open('tel:+923341666222')}
                  >
                    Call Now
                  </Button>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};


