import React, { useState } from "react";
import 'react-lazy-load-image-component/src/effects/blur.css';
import roomone from "../../assets/images/room/roomone.jpg";
import roomtwo from "../../assets/images/room/roomtwo.jpg";
import roomthree from "../../assets/images/room/roomthree.jpg";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useMediaQuery,
  TextField
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { ProductCard } from "../product-card";
import { Add, ArrowForward } from "@mui/icons-material";
import { accordiandata, sliderdata } from "./data";
import { Link } from "react-router-dom";

const Rooms = () => {
  const [expand, setExpand] = useState(0);
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'));
  const isSm = useMediaQuery(theme.breakpoints.up('sm'));
  const isXs = useMediaQuery(theme.breakpoints.down('sm')); // New hook for mobile screens
  const fontSizeLarge = isMd ? '35px' : isSm ? '20px' : '20px';
  const fontSizeSmall = isMd ? '20px' : isSm ? '15px' : '10px';
  const [totalSize, setTotalSize] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleChange = (e)=> {
    let totalSize = height * width;
    setTotalSize(totalSize);
    
  }

  const imgStyles = {
    width: isXs ? '100%' : 'auto', // Apply 100% width on mobile screens
    height: 'auto',
  };

  return (
    <Box sx={styles.wrapper}>
      <Container maxWidth="xl">
        <Grid container>
          <Grid item md={12} sm={6} xs={12}>
            <Box mb={4} sx={{ display: 'flex', justifyContent: 'space-around' }}>
              <Typography sx={{ color: '#181818', fontSize: fontSizeLarge }}>
                Living Room
              </Typography>
              <Typography sx={{ color: '#181818', fontSize: fontSizeLarge }}>
                Drawing Room
              </Typography>
              <Typography sx={{ color: '#181818', fontSize: fontSizeLarge }}>
                Bed Room
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid container spacing={2}>
          <Grid item md={3} xs={12}>
            <div data-aos="fade-up">
              <Swiper
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
              >
                {sliderdata.map((num) => (
                  <SwiperSlide key={num}>
                    <ProductCard img={num.img} hideContent imgHeight="550px" imgStyles={imgStyles} />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Grid>
          <Grid item md={9} xs={12}>
            <Grid container spacing={2}>
              <Grid item md={4} sm={6} xs={12}>
                <div data-aos="flip-left">
                  <ProductCard img={roomone} hideContent imgHeight={"270px"} imgStyles={imgStyles} lazyLoad />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div data-aos="flip-up">
                  <ProductCard img={roomtwo} hideContent imgHeight={"270px"} imgStyles={imgStyles} lazyLoad />
                </div>
              </Grid>
              <Grid item md={4} sm={6} xs={12}>
                <div data-aos="flip-right">
                  <ProductCard img={roomthree} hideContent imgHeight={"270px"} imgStyles={imgStyles} lazyLoad />
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div data-aos="flip-down">
                  <Typography mt="20px" color="#181818" fontSize={40}>
                    Why Living Style?
                  </Typography>
                  <Typography color="#1F1F1FE5" fontSize={26}>
                    Wallpaper that speaks volumes
                  </Typography>
                  <Typography color="#141414" fontSize={16} mb="10px" mt="10px">
                    Upgrade your bedroom, living, office spaces and outhouses with our perfect wallpaper collections. Living Style is here to deliver quality designs and best fits.
                    experience.
                  </Typography>
                </div>
                <Typography fontSize={{ xs: 22, md: 24 }}
                color="#17183B"
                mb="20px"
                lineHeight={2}>Size Calulator:</Typography>
                <div data-aos="fade-in" style={{display: 'flex'}}>
                 {/* <Link to="/product">
                 <Button
                    endIcon={<ArrowForward />}
                    variant="outlined"
                    disableRipple
                    sx={{
                      padding: "14px 35px",
                      color: "#181818",
                      borderColor: "#181818",
                      "&:hover": {
                        color: "#181818",
                        borderColor: "#181818",
                        background: "transparent",
                      },
                    }}
                  >
                    View Collection
                  </Button>
                 </Link> */}
                 
                 <TextField 
                  inputMode="numeric" 
                  label="Height ft" 
                  value={height}
                  name = 'height'
                  onBlur = {handleChange}
                  onChange = {(e) => setHeight(e.target.value)}
                  ></TextField>
                  <Typography style={{marginLeft:'1%', alignSelf: 'center', marginRight: '1%'  }}>*</Typography>
                <TextField 
                  inputMode="numeric" 
                  label="Width ft" 
                  value = {width}
                  name = 'width'
                  onChange = {(e)=> setWidth(e.target.value)}
                  onBlur = {handleChange}
                ></TextField>
                  
                <Typography style={{marginLeft:'1%', alignSelf: 'center'}}>=</Typography>
                <Typography style={{marginLeft:'1%', alignSelf: 'center'}}>{totalSize}SqFt</Typography>
                </div>
              </Grid>
              <Grid item md={6} xs={12}>
                <div data-aos="zoom-in">
                  {accordiandata.map((num, i) => (
                    <Accordion
                      key={num}
                      expanded={i === expand}
                      onChange={() => setExpand(i)}
                      sx={{ background: "transparent", boxShadow: "none" }}
                    >
                      <AccordionSummary
                        expandIcon={<Add />}
                        sx={{ borderBottom: "1px solid #BCBCBC" }}
                      >
                        <Typography
                          fontSize={20}
                          sx={{
                            color: "#252424",
                          }}
                        >
                         {num.question}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography
                          sx={{ color: "#141414" }}
                          variant="subtitle1"
                        >
                         {num.answer}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </div>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Rooms;

const styles = {
  wrapper: {
    background: "#F6F5F3",
    py: "70px",
    "& .mySwiper": {
      height: "620px !important",
      "&.swiper-backface-hidden": {
        overflowX: "hidden",
        overflowY: "visible",
        "&::-webkit-scrollbar": {
          width: 0,
        },
      },
      "& .swiper-wrapper": {
        position: "static",
      },
      "& .swiper-button-prev, & .swiper-button-next": {
        top: "95%",
        background: "#E5E5E4",
        height: "50px",
        width: "50px",
        borderRadius: "50px",
        color: "#222",
        "&::after": {
          fontSize: "25px !important",
        },
      },
      "& .swiper-button-next": {
        left: "20% !important",
      },
    },
  },
};
