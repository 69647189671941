import React, { useState, useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import {
  Box,
  Button,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  TextField,
  Typography,
  Checkbox,
} from "@mui/material";
import location from "../../assets/images/location.png";
import message from "../../assets/images/message.png";
import call from "../../assets/images/call.png";
import sendMail from "../../components/mail";

export const ContactUs = () => {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const [selectedSubjects, setSelectedSubjects] = useState([]);

  const handleSubjectChange = (event) => {
    const selectedSubject = event.target.name;
    setSelectedSubjects((prevSelected) =>
      prevSelected.includes(selectedSubject)
        ? prevSelected.filter((subject) => subject !== selectedSubject)
        : [...prevSelected, selectedSubject]
    );
  };

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNum: '',
    message: ''
  }); 

  const handleFormData = (event) => {
    const { name, value } = event.target;
    setFormData(prevForm => ({
        ...prevForm,
        [name]: value
    }));
};

  const sendEmail = () =>{
    let text = `First Name: ${formData.firstName} \nLast Name: ${formData.lastName} \n`
                 + `Email: ${formData.email} \nPhone Number: ${formData.phoneNum} \n`
                 + `Message: \n\t ${formData.message}`;
                     
    let subject =selectedSubjects.length > 0 ? `Contact Us - ${selectedSubjects.join(", ") }`: 'Contact Us';
    
    if(formData.email.length > 0 && formData.message.length > 0){
      sendMail(subject,text);
    }
  }

  return (
    <Box py={{ xs: "50px", md: "110px" }} px={{ xs: "20px", md: "79px" }}>
      <Container maxWidth="xl">
        <Typography
          data-aos="fade-down"
          color="#000000"
          fontSize={{ xs: "40px", md: "50px" }}
          textAlign="center"
        >
          Contact Us
        </Typography>
        <Typography
          data-aos="fade-down"
          color="#717171"
          fontSize={{ xs: "20px", md: "24px" }}
          textAlign="center"
        >
          If you have any orders, inquiries or complaints,<br /> just shoot a message right away!
        </Typography>
        <Box sx={styles.wrapper}>
          <Grid container spacing={{ xs: 2, md: 4 }}>
            <Grid item md={5} xs={12}>
              <Box sx={styles.contact_card} data-aos="fade-right" mb={4}>
                <Typography color="#fff" fontSize={{ xs: 30, md: 40 }}>
                  Contact Information
                </Typography>
                <Box mt={4}>
                  <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                    <Box component="img" src={call} alt="Call" sx={styles.logo} />
                    <Typography color="#fff" fontSize={18}>
                      <a href="tel:+923341666222" style={{ color: "#fff", textDecoration: "none" }}>+923341666222</a>
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2, mb: 4 }}>
                    <Box
                      component="img"
                      src={message}
                      alt="Message"
                      sx={styles.logo}
                    />
                    <Typography color="#fff" fontSize={18}>
                      info@livingstyle.pk
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <Box
                      component="img"
                      src={location}
                      alt="Location"
                      sx={styles.logo}
                    />
                    <Typography color="#fff" fontSize={18}>
                      <a
                        href="https://www.google.com/maps/place/Building+%23+21-C/ll,+Lane+9,+Bukhari+Commercial,+Phase+6,+DHA,+Karachi,+Pakistan"
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ color: "#fff", textDecoration: "none" }}
                      >
                        Building # 21-C/ll, Lane 9, Bukhari Commercial, Phase 6, DHA, Karachi, Pakistan
                      </a>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item md={7} xs={12}>
              <Grid container spacing={{ xs: 2, md: 4 }}>
                <Grid item xs={12} md={6} data-aos="fade-up">
                  <TextField
                    id="standard-basic"
                    label="First Name"
                    variant="standard"
                    fullWidth
                    sx={styles.input}
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleFormData}
                  />
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-up">
                  <TextField
                    id="standard-basic"
                    label="Last Name"
                    variant="standard"
                    fullWidth
                    sx={styles.input}
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleFormData}
                  />
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-up">
                  <TextField
                    id="standard-basic"
                    label="Email"
                    variant="standard"
                    fullWidth
                    sx={styles.input}
                    name="email"
                    value={formData.email}
                    onChange={handleFormData}
                  />
                </Grid>
                <Grid item xs={12} md={6} data-aos="fade-up">
                  <TextField
                    id="standard-basic"
                    label="Phone Number"
                    variant="standard"
                    fullWidth
                    sx={styles.input}
                    name="phoneNum"
                    value={formData.phoneNum}
                    onChange={handleFormData}
                  />
                </Grid>
                <Grid item xs={12} mb={{ xs: "20px", md: "20px" }} data-aos="fade-up">
                  <Typography
                    color="#000000"
                    variant="subtitle1"
                    fontWeight={600}
                    mt={{ xs: "20px", md: "20px" }}
                    mb={{ xs: "10px", md: "19px" }}
                  >
                    Select Subject?
                  </Typography>
                  <FormGroup row>
                    <FormControlLabel
                      control={<Checkbox checked={selectedSubjects.includes('Orders')} onChange={handleSubjectChange} name="Orders" />}
                      label="Orders"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={selectedSubjects.includes('Track Orders')} onChange={handleSubjectChange} name="Track Orders" />}
                      label="Track Orders"
                    />
                    <FormControlLabel
                      control={<Checkbox checked={selectedSubjects.includes('Complaints')} onChange={handleSubjectChange} name="Complaints" />}
                      label="Complaints"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <TextField
                    id="standard-multiline-flexible"
                    label="Message"
                    multiline
                    rows={2}
                    variant="standard"
                    fullWidth
                    sx={styles.input}
                    name="message"
                    value={formData.message}
                    onChange={handleFormData}
                  />
                </Grid>
                <Grid item xs={12} data-aos="fade-up">
                  <Box>
                    <Button disableRipple sx={styles.btn} onClick={sendEmail}>
                      Send Message
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

const styles = {
  wrapper: {
    boxShadow: "0px 0px 4px rgba(0,0,0,0.07)",
    borderRadius: "4px",
    p: { xs: "10px", md: "20px" },
    pb: { xs: "50px", md: "90px" },
    mt: { xs: "20px", md: "59px" },
  },
  contact_card: {
    padding: { xs: "20px", md: "40px" },
    borderRadius: "5px",
    background: "#000000",
    transform: { md: "translate(0, 0)", xs: "translate(0px, 0px)" },
    height: "100%",
  },
  input: {
    "& .MuiFormLabel-root": {
      color: "#000000",
    },
    "& .MuiInputBase-root": {
      "&::before": {
        borderColor: "#000000",
      },
      "&::after": {
        borderColor: "#000000",
      },
    },
  },
  btn: {
    color: "#fff",
    background: "#000000",
    mt: { xs: "20px", md: "40px" },
    fontSize: "16px",
    padding: { xs: "15px", md: "24px" },
    "&:hover": {
      color: "#fff",
      background: "#000000",
    },
  },
  logo: {
    width: "25px",
    height: "25px",
    objectFit: "contain",
    marginTop: '5px'
  },
};
