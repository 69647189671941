import React from "react";
import {
  Box,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  Pagination,
  Typography,
} from "@mui/material";
import HeroSection from "../../components/hero-section";
import { ProductCard } from "../../components/product-card";
import newone from "../../assets/images/productslider/thumbnaildetailsone1.jpg"
import newtwo from "../../assets/images/productslider/pd2thum.jpg"
import newthree from "../../assets/images/productslider/pd3thumb.jpg";
import newfour from "../../assets/images/productslider/pd4thumb.jpg";
import newfive from "../../assets/images/productslider/pd5thumb.jpg";
import newsix from "../../assets/images/productslider/pd6thumb.jpg";
import newseven from "../../assets/images/productslider/pd7thumb.jpg";
import neweight from "../../assets/images/productslider/pd8thumb.jpg";
import newnine from "../../assets/images/productslider/pd9thumb.jpg";
import newten from "../../assets/images/productslider/pd10thumb.jpg";
import { Link } from "react-router-dom"



const Products = () => {
  const Categories = ["Chivalry", "Makamae", "Jute", "Texture","Italian Chic","Lobel","Bazar Nonvoven","Verona","Zen","Designer"];
  const CategoriesLink = [
    {
      name: "Chivalry",
      link: "/products-detail"
    },
    {
      name: "Makamae",
      link: "/productdetail-two"
    },
    {
      name: "Jute",
      link: "/productsdetail-three"
    },
    {
      name: "Texture",
      link: "/productdetails-four"
    },
    {
      name: "Italian Chic",
      link: "/productsdetail-five"
    },
    {
      name: "Lobel",
      link: "/productdetail-six"
    },
    {
      name: "Bazar Nonvoven",
      link: "/productdetail-seven"
    },
    {
      name: "Verona",
      link: "/productdetail-eight"
    },
    {
      name: "Zen",
      link: "/productdetail-nine"
    },
    {
      name: "Designer",
      link: "/productdetail-ten"
    }
  ];
  const handleClick = (cat)=>{
    let catLink = CategoriesLink.find(x=> x.name === cat);
    return catLink? catLink.link : 'product-detail'

  }
  
  return (
    <>
      <HeroSection />
      <Box sx={{ p: { md: "141px 0px", xs: "100px 20px" } }}>
        <Container maxWidth="lg">
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3}>
              <div
                data-aos="fade-right"
                data-aos-offset="300"
                data-aos-easing="ease-in-sine"
              >
               
                <Box mb="57px">
                  <Typography
                    component="span"
                    color="#111111"
                    fontSize={{ xs: 24, md: 30 }}
                    fontWeight={800}
                    pb="10px"
                    sx={{
                      borderBottom: "1px solid #111111",
                    }}
                  >
                    Categories
                  </Typography>
                </Box>
                <Box display="flex" flexDirection="column" gap="20px">
                  {Categories.map((cat) => (
                    // <Typography
                    //   color="#181818"
                    //   key={cat}
                    //   fontSize={{ xs: 16, md: 18 }}
                    //   sx={{ cursor: "pointer" }} 
                    //   link = {function (){
                    //     let catLink = CategoriesLink.find(x=> x.name === cat);
                    //     return catLink? catLink.link : 'product-detail'
                    //   }}
                                 
                    // >
                    //   {cat}
                    // </Typography>
                    <Link to={handleClick(cat)} style={{ textDecoration: 'none', color: 'Black' }} >{cat}</Link>
                  ))}
                </Box>
                
              </div>
            </Grid>
            <Grid item xs={12} lg={9}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                flexDirection={{ xs: "column", md: "row" }}
                gap={{ xs: "20px", md: "0px" }}
                mb={{ xs: "20px", md: "40px" }}
              >
                <Typography fontSize={{ xs: 30, md: 60 }}>New Arrivals</Typography>
              
              </Box>
              <Grid container spacing={2}>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newone}
                      imgHeight={"200px"}
                      title="Chivalry"
                      link={"/products-detail"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newtwo}
                      imgHeight={"200px"}
                      title="Makamae"
                      link={"/productdetail-two"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newthree}
                      imgHeight={"200px"}
                      title="Jute"
                      link={"/productsdetail-three"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newfour}
                      imgHeight={"200px"}
                      title="Texture"
                      link={"/productdetails-four"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newfive}
                      imgHeight={"200px"}
                      title="Italian Chic"
                      link={"/productsdetail-five"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newsix}
                      imgHeight={"200px"}
                      title="Lobel"
                      link={"/productdetail-six"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newseven}
                      imgHeight={"200px"}
                      title="Bazar Nonvoven"
                      link={"/productdetail-seven"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={neweight}
                      imgHeight={"200px"}
                      title="Verona"
                      link={"/productdetail-eight"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newnine}
                      imgHeight={"200px"}
                      title="Zen"
                      link={"/productdetail-nine"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
                <Grid item lg={4} md={4} sm={6} xs={12}>
                  <Box sx={{ display: 'flex', justifyContent: 'center' }} data-aos="fade-up" data-aos-duration="3000">
                    <ProductCard
                      img={newten}
                      imgHeight={"200px"}
                      title="Designer"
                      link={"/productdetail-ten"}
                      onClick={() => { }}
                    />
                  </Box>
                </Grid>
               
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Products;
const styles = {
  wrapper: {

    "& .MuiPagination-root": {
      borderRadius: "5px",
      border: "1px solid #000000",
      padding: "14px",
      "& .css-yuzg60-MuiButtonBase-root-MuiPaginationItem-root": {
        minWidth: '0px'
      },
    },

  },
};

