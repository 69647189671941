import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";

export const ProductCard = ({
  img,
  title,
  subtitle,
  onClick,
  hideBtn,
  denseSpacing,
  hideContent,
  imgHeight,
  link,
}) => {
  return (
    <Box sx={styles.card(denseSpacing, imgHeight)}>
      <img src={img} className="_img" alt="img" />
      {!hideContent && (
        <Box sx={styles.content(denseSpacing)}>
          <Box sx={hideBtn ? styles.contentWrapper : {}} flex={1}>
            <Typography color="#181818" fontSize={denseSpacing ? 14 : 20}>
              {title}
            </Typography>
            <Typography color="#4D4D4D" fontSize={denseSpacing ? 13 : 17}>
              {subtitle}
            </Typography>
          </Box>
          {!hideBtn && (
            <Link to={link}>
              <Button
                variant="outlined"
                disableRipple
                sx={styles.viewBtn}
                onClick={onClick}
              >
                View More
              </Button>
            </Link>
          )}
        </Box>
      )}
    </Box>
  );
};

const styles = {
  card: (denseSpacing, imgHeight) => ({
    "& ._img": {
      height: denseSpacing ? "270px" : imgHeight ?? "320px",
      width: "100%",
    },
  }),
  content: (denseSpacing) => ({
    pt: denseSpacing ? "10px" : "32px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  }),
  contentWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  viewBtn: {
    borderRadius: "3px",
    color: "#000000",
    borderColor: "#000000",
    "&:hover": {
      color: "#000000",
      borderColor: "#000000",
      background: "#fff",
    },
  },
};
