import React, { useState, useEffect } from "react";
import { Box, Button, Container, Grid, Typography, TextField } from "@mui/material";
import { ProductCard } from "../../components/product-card";
import slideImg from "../../assets/images/productslider/pd5thumb.jpg";
import bgTwo from "../../assets/images/productslider/pd5two.jpg";
import bgThree from "../../assets/images/productslider/pd5three.jpg";
import bgFour from "../../assets/images/productslider/pd5four.jpg";
import { ArrowLeft, ArrowRight } from "@mui/icons-material";
import AOS from "aos";
import "aos/dist/aos.css";
import heroright from "../../assets/images/heroRight.png";
import abouttwo from "../../assets/images/roomtwo.png";
import catalogue from "../../assets/catalogues/Italian Chic.pdf";

const detaildata = [
  {
      id: 1,
      img: heroright,
  },
  {
      id: 2,
      img: abouttwo,
  }
]
export const ProductDetailFive = () => {
  useEffect(() => {
    AOS.init({ duration: 1000, offset: 200 });
  }, []);

  const imgArray = [slideImg, bgTwo, bgThree, bgFour];
  const [img, setImg] = useState(0);
  const [tab, setTab] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);

  const handleChange = (e)=> {
    let totalSize = height * width;
    setTotalSize(totalSize);
    
  }

  return (
    <Box px={{ xs: "10px", md: "20px" }} py="40px">
      <Container maxWidth="xl">
        <Grid container spacing={4}>
          <Grid item md={5} xs={12}>
            <div data-aos="zoom-out-up">
              <ProductCard
                imgHeight={{ xs: "200px", md: "300px" }}
                img={imgArray[img]}
                hideContent
              />
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                mt="20px"
                flexDirection="row"
              >
                {imgArray.map((src, i) => (
                  <Box
                    key={i}
                    onClick={() => setImg(i)}
                    sx={{
                      borderRadius: "4px",
                      border: "2px solid #D1D1D8",
                      padding: "8px",
                      width: { xs: "80px", md: "100px" },
                      height: { xs: "80px", md: "100px" },
                      cursor: "pointer",
                      "& img": {
                        borderRadius: "4px",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      },
                    }}
                  >
                    <img src={src} alt={"img"} />
                  </Box>
                ))}
              </Box>
            </div>
          </Grid>
          <Grid item md={7} xs={12}>
            <div data-aos="zoom-out">
            
              <Typography
                fontSize={{ xs: 16, md: 18 }}
                color="#17183B"
                mb="20px"
                lineHeight={2}
              >
               Elevate your home decor with our high-quality, durable wallpaper featuring a variety of captivating patterns and colors. Whether you prefer a modern geometric design, a classic floral motif, or a bold abstract print, our collection offers something for every taste. Easy to install and maintain, our wallpaper is the perfect choice to add a touch of sophistication and personality to your living space. Redefine your walls with our exquisite wallpaper designs today!
              </Typography>
              <Button
                variant="contained"
                disableRipple
                sx={{
                  background: "#252324",
                  color: "#fff",
                  padding: "14px 30px",
                  "&:hover": {
                    background: "#252324",
                  },
                }}
              >
                Call Now
              </Button>

              <Button
                variant="contained"
                disableRipple
                sx={{
                  background: "#252324",
                  color: "#fff",
                  padding: "14px 30px",
                  "&:hover": {
                    background: "#252324",
                  },
                  marginLeft: '15px'
                }}
                href = {catalogue}
                target = "_blank"
              >
                Download Catalogue
              </Button>
              <br></br>
              <br></br>
              <Typography fontSize={{ xs: 22, md: 24 }}
                color="#17183B"
                mb="20px"
                lineHeight={2}>Size Calulator:</Typography>
              <div style={{display:'flex'}}>
                <TextField 
                  inputMode="numeric" 
                  label="Height ft" 
                  value={height}
                  name = 'height'
                  onBlur = {handleChange}
                  onChange = {(e) => setHeight(e.target.value)}
                  ></TextField>
                  <Typography style={{marginLeft:'1%', alignSelf: 'center', marginRight: '1%'  }}>*</Typography>
                <TextField 
                  inputMode="numeric" 
                  label="Width ft" 
                  value = {width}
                  name = 'width'
                  onChange = {(e)=> setWidth(e.target.value)}
                  onBlur = {handleChange}
                ></TextField>
                  
                <Typography style={{marginLeft:'1%', alignSelf: 'center'}}>=</Typography>
                <Typography style={{marginLeft:'1%', alignSelf: 'center'}}>{totalSize}SqFt</Typography>
              </div>
            </div>
          </Grid>
        </Grid>
      </Container>
     
    </Box>
  );
};
