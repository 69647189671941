import newone from "../../assets/images/homepage/newone.jpg"
import newtwo from "../../assets/images/homepage/newtwo.jpg"
import newthree from "../../assets/images/homepage/newthree.jpg"
import newfour from "../../assets/images/homepage/newfour.jpg"
import newfive from "../../assets/images/homepage/newfive.jpg"
import newsix from "../../assets/images/homepage/newsix.jpg"
import newseven from "../../assets/images/homepage/newseven.jpg"
import neweight from "../../assets/images/homepage/neweight.jpg"


export const newarrivaldata = [
    {
        id: 1,
        img: newone 
    },
    {
        id: 2,
        img:  newtwo 
    },
     {
        id: 3,
        img:  newthree 
    },
     {
        id: 4,
        img:  newfour
    },
    {
        id: 5,
        img: newfive 
    },
    {
        id: 6,
        img:  newsix
    }, {
        id: 7,
        img:  newseven 
    }, {
        id: 8,
        img:  neweight
    },
]